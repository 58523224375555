// External
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, forkJoin, throwError } from 'rxjs';
import { catchError, map, skipWhile, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

// Internal
import { ConnectMgmtService, ListDevicesV2ExtraParams } from '../../requests/connect-mgmt-service/connect-mgmt.service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { OperatingSystems, ProductsToInstall, ValuesService } from '../../../../common/values/values.service';
import { ConfigService } from '../../../../common/config/config.service';
import { AppEnableStatus, DevicesValuesService } from '../../../values/devices.values';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { SettingsService } from '../settings/settings.service';
import { ProfilesService } from '../profiles/profiles.service';
import { SubscriptionsValuesService } from '../../../values/subscriptions.values.service';
import { AppsConfigService } from '../../../../common/config/apps.config.service';
import { ProductsConfigService } from '../../../../common/config/products.config.service';
import { ModalRoutelessService } from '../../../../common/components/ui/ui-modal-routeless/modal.routeless.service';
import { AppMgmtService } from '../../requests/connect-app-mgmt-service/connect-app-mgmt.service';
import { MessageService } from '../../core/message.service';
import { DevicesByStatus } from '../../../../common/models/devices/Devices.model';
import { DeviceModel } from '../../../models/Services.model';

@Injectable({
    providedIn: 'root'
})

export class DevicesService {

    readonly onlistDevices$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    readonly onlistDevicesOnly$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    readonly onlistExceptions$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    private markToUpdateDevices = true;
    private markToUpdateDevicesTraffic = true;
    private markToUpdateExceptions = true;

    trafficChangedIds = new Set();

    traffic = {};
    trafficInProgress = new Set();

    devices: any = {
        all: [],
        allObject: {},
        count: 0,
        crcObject: {}
    };

    tempDevices: any = {
        all: [],
        allObject: {},
        count: 0,
        crcObject: {}
    };

    private atLeastOneSuccessfulDevicesRequest = false;
    appsDisplayRules = {
        [this.valuesService.appPA] : {
            display: item => this.subscriptionsService.hasParental() && !this.deviceHasSoho(item)
        },
        [this.valuesService.appPANCC] : {
            display: () => this.subscriptionsService.hasParentalNCC()
        }
    };

    boxIds: any = [];
    exceptions: any = [];

    constructor(
        private readonly valuesService: ValuesService,
        private readonly utilsService: UtilsCommonService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly subscriptionsValuesService: SubscriptionsValuesService,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly settingsService: SettingsService,
        private readonly profilesService: ProfilesService,
        private readonly devicesValuesService: DevicesValuesService,
        private readonly connectMgmtService: ConnectMgmtService,
        private readonly configService: ConfigService,
        private readonly translate: TranslateService,
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly appsConfigService: AppsConfigService,
        private readonly productsConfigService: ProductsConfigService,
        private readonly messageService: MessageService,
        private readonly appMgmtService: AppMgmtService
    ) {
        /* Used for updating the request in order to write them into th db by the service worker */
        this.messageService.getDynamicSubject(this.valuesService.events.resetRequestsForServiceWorker)
        .subscribe({
            next: () => {
                this.updateDevices();
                this.updateExceptions();
            }
        });

    }

    getProtectionAppLevel(item) {
        return item.subscription_info ? item.subscription_info.level : undefined;
    }

    getInstalledProtectionAppId(appIds, item) {
        for (const appId of appIds) {
            for (const installedAppId of item.processed.installed_apps) {
                if (appId === installedAppId) {
                    return appId;
                }
            }
        }

        return null;
    }

    getInstalledAppId(appId, item) {
        for (const installedAppId of item.processed.installed_apps) {
            if (appId === installedAppId) {
                return appId;
            }
        }

        return null;
    }


    checkMethods = {
        // item.apps - app instalate pe device

        /**
         * are aplicatia nativa - de central
         **/
        isManagement: item => item?.apps?.length === 1 && item?.apps[0]?.app_id === this.valuesService.connectAppId,

        /**
         * este un device de tip network equipmant
         **/
        isBox: item => item?.device_type === this.devicesValuesService.deviceType.NETWORK_EQUIPMENT,

        /**
         * Checks if device has box_managed flag and if box_device_id is a valid box device_id
         * @param item Device
         * @returns Boolean, true if device is box managed
         */
        isBoxManaged: item => {
            if (!item.box_managed) {
                return false;
            }

            const boxId = item.box_device_id;
            if (boxId && this.devices.allObject[boxId] && this.checkMethods.isBox(this.devices.allObject[boxId])) {
                return true;
            }
            return false;
        },

        isTempBoxManaged: item => {
            if (!item.box_managed) {
                return false;
            }

            const boxId = item.box_device_id;
            if (boxId && this.tempDevices.allObject[boxId] && this.checkMethods.isBox(this.tempDevices.allObject[boxId])) {
                return true;
            }
            return false;
        },

        /**
         * este un device de tip IoT
         **/
        isIoT: item => {
            let isIot = true;
            Object.keys(this.devicesValuesService.deviceCategories).forEach(categ => {
                if (this.devicesValuesService.deviceCategories[categ].indexOf(item.device_type) > -1 || this.checkMethods.isBox(item)) {
                    isIot = false;
                }
            });
            return isIot;
        },

        /**
         * daca are o aplicatie de protectie sau una de box
         **/
        hasProtectionAppInstalled: item => {
            if (!item.apps) {
                return false;
            }
            return item.processed.hasBoxProtectionApp || item.processed.hasProtection;
        },

        /**
         * Check if device has clones
         */
        hasClones: item => item?.clones?.length > 0
    };

    processingMethods = {
        addAccounts: item => {
            if (item.device_accounts
                && item.device_accounts.length > 0
                && item.device_os === OperatingSystems.WINDOWS) {

                let allAccounts = false;
                for (const account of item.device_accounts) {
                    if (account.sid === 0) {
                        allAccounts = true;
                        break;
                    }
                }
                const deviceAccounts = allAccounts ? item.device_accounts : [{sid: 0, name: this.translate.instant('devices.all.accounts')}].concat(item.device_accounts);
                item.processed.deviceAccounts = deviceAccounts;
                const sid = item.device_account_sid;
                let accountIndex = 0;

                for (let i = 0; i < deviceAccounts.length; i++) {
                    if (sid === deviceAccounts[i].sid) {
                        accountIndex = i + 1;
                    }
                }
                item.processed.deviceAccountsIndex = accountIndex;
            }
        },

        addConnectivityFlags: item => {
            if (!this.checkMethods.isBox(item)) {
                // isConnected trebuie sa fie boolean
                item.processed.isConnected = !!(item.box_device_id
                                            && this.tempDevices.allObject[item.box_device_id]
                                            && !this.tempDevices.allObject[item.box_device_id].processed.boxOffline
                                            && item.onbox);
            }
        },

        /**
         * populam .processed.installed_apps cu lista de aplicatii instalate de utilizator
         **/
        addInstalledApps: item => {
            item.processed.apps_obj = {};
            item.processed.installed_apps = [];

            if (item.apps === undefined) {
                return;
            }

            for (const app of item.apps) {
                item.processed.apps_obj[app.app_id] = app;
                if (app.state === this.devicesValuesService.appState.IS_INSTALLED
                    && this.valuesService.appsSet.has(app.app_id)) {
                    item.processed.installed_apps.push(app.app_id);
                    item.processed.apps_obj[app.app_id] = app;
                }
            }
        },

        /**
        * daca device-ul e iot sau device care poate fi protejat doar de box si box managed se arata o lista cu vulnerabilities in device-details
        **/
        checkIfHasVulnerabilitySectionDisplayed: item => !!(item.processed.productsIncompatible && this.checkMethods.isBoxManaged(item) && item.processed.isConnected),

        addCrc: item => {
            const crc = this.utilsCommonService.crc32Convert_old(item.device_id);
            item.processed.device_crc = crc;
            this.tempDevices.crcObject[crc] = item;
        },

        addBoxManagedFlag: item => {
            if (!this.checkMethods.isBox(item)) {
                item.processed.box_managed = this.checkMethods.isTempBoxManaged(item);
            }
        },

        /**
         *! metoda se apeleaza dupa popularea box_managed_sorted si dupa checkBoxOffline
         * PROTECTED: subscriptia valid + online
         * AT_RISK: subscriptia e expirata/boxul nu ocupa slot
         * DISCONNECTED: e disconnected
         **/
        addBoxProtectionStatus: item => {
            if (!this.checkMethods.isBox(item)) {
                return;
            }

            let protectionStatus = this.valuesService.protectionStatus.PROTECTED;
            if (this.subscriptionsService.getBoxSubscriptionStatus(item.device_id) !== this.subscriptionsValuesService.status.active) {
                protectionStatus = this.valuesService.protectionStatus.AT_RISK;
            } else if (item.processed.boxOffline) {
                protectionStatus = this.valuesService.protectionStatus.DISCONNECTED;
            }

            item.processed.protectionStatus = protectionStatus;
        },

        addBoxCounters: item => {
            if (!this.checkMethods.isBox(item)) {
                return;
            }

            const onBoxDevices = this.tempDevices.box_managed_sorted[item.device_id] ? this.tempDevices.box_managed_sorted[item.device_id] : [];
            let nrProtectedDevices = 0;
            let nrProtectedIOTDevices = 0;
            for (const device of onBoxDevices) {
                if (device.processed.protectionStatus === this.valuesService.protectionStatus.PROTECTED) {
                    if (this.checkMethods.isIoT(device)) {
                        nrProtectedIOTDevices++;
                    } else {
                        nrProtectedDevices++;
                    }
                }
            }

            item.processed.nrProtectedDevices = nrProtectedDevices;
            item.processed.nrProtectedIOTDevices = nrProtectedIOTDevices;
        },

        addSettings: device => {
            const deviceSettings = this.settingsService.getDeviceSettings(device.device_id);
            if (deviceSettings || this.checkMethods.isBox(device)) {
                device.processed.device_settings = deviceSettings;
            }
        },

        /**
         * statusul device-ului
         * DISCONNECTED
         * AT_RISK
         * PROTECTED
         **/
        addDeviceProtectionStatus: item => {
            if (this.checkMethods.isBox(item)) {
                return;
            }

            const hasIssues = !!item?.protection_status?.issues;
            const isConnected = item?.processed?.isConnected;
            const hasBoxProtection = item.processed.hasBoxProtectionApp;
            const hasProtection = item.processed.hasProtection;
            // ia in considerare si statsusul subscriptiei pt a afisa statusul final
            const validProtection = item?.processed?.protectionApp;
            const deviceOs = this.getDeviceOsBasedOnProtectionApp(item);
            const showProtection = this.appsConfigService.showApp(this.valuesService.compatibleOsInfo[deviceOs]?.appId);

            // are doar protectie oferita de box si e offline boxul sau device-ul
            if ((!validProtection || !this.valuesService.protectionApps.has(validProtection))
                && !hasProtection
                && hasBoxProtection
                && isConnected === false) {
                item.processed.protectionStatus = this.valuesService.protectionStatus.DISCONNECTED;
            // nu are protectie de niciun fel sau are protectie dar nu are issues
            // trebuie obligatoriu sa poata avea protectie in general
            } else if (((!validProtection && (!hasBoxProtection || hasProtection)) || hasIssues) && showProtection) {
                item.processed.protectionStatus = this.valuesService.protectionStatus.AT_RISK;
            // are protectie si nu are issues
            } else {
                item.processed.protectionStatus = this.valuesService.protectionStatus.PROTECTED;
            }
            item.processed.showProtection = showProtection;
        },

        /**
        * se ia versiunea de cl instalata
        **/
        addClVersion: item => {
            if (!item.apps) {
                return;
            }

            //! de vazut versiunea noua a fisierului!!!
            for (const app of item.apps) {
                if (app.app_id === this.valuesService.appCL || app.app_id === this.valuesService.appWS) {
                    //inainte era clVersion
                    item.processed.hasCl = true;
                    if(app.hasOwnProperty('installed_version')){
                        item.processed.clMajorVersionInstalled = parseInt(app.installed_version.split('.')[0], 10);
                    } else if(app.hasOwnProperty('pending_install_version')){
                        item.processed.clMajorVersionPending = parseInt(app.pending_install_version.split('.')[0], 10);
                        item.processed.clMajorVersionInstalled = '0';
                    } else {
                        item.processed.clMajorVersionInstalled = '0';
                    }
                }
            }
        },

        /**
        * se adauga services per device
        **/
        addSubscriptions: item => {
            item.processed.services = [];
            const services = this.subscriptionsService.getAllFilteredActiveServices();
            for (const service of services) {
                const accounts = service?.accounts;
                if (!accounts) {
                    continue;
                }

                for (const account of accounts) {
                    const usages = account?.usage_summary;
                    if (this.profilesService.getOwnerEmail() !== account.email || !usages) {
                        continue;
                    }
                    for (const usage of usages) {
                        if (usage.slot_id === item.device_id) {
                            item.processed.services.push(service);
                        }
                    }
                }
            }
        },

        /**
         * Adds box type/version (BOX1 or BOX2) for all boxes
         * @param item Device
         * @returns
         * @returns
         */
        addBoxVersion: item => {
            if (!this.checkMethods.isBox(item)) {
                return;
            }

            if (!item.apps) {
                item.processed.box_version = this.devicesValuesService.boxVersion.BOX1;
            } else {
                item.processed.box_version = this.devicesValuesService.boxVersion.BOX2;
            }
        },

        addIotFlag: item => {
            if (!this.checkMethods.isIoT(item)) {
                return;
            }
            item.processed.isIot = true;
        },

        /**
         * Ads productsIncompatible = true if device is iot or has a device os that is incompatible with all Bitdefender products
         * @param item Device
         * @returns
         */
        addProductsIncompatible: item => {
            const deviceOs = this.getDeviceOsBasedOnProtectionApp(item);
            if (!this.valuesService.compatibleProtectionApps[deviceOs]) {
                item.processed.productsIncompatible = true;
            }
        },

        addSubscriptionInfo: (item, currentBundle, options) => {
            if (!item.processed) {
                item.processed = {};
            }

            const subscriptionsInfo = {
                current_bundle: currentBundle,
                options
            };

            item.processed.subscriptionsInfo = subscriptionsInfo;
        },

        addSubscriptionsFlag: item => {
            const protectionApp = item?.protection_status?.protection_app;
            const validProtection = item?.protection_status?.protected;
            const deviceOs = this.getDeviceOsBasedOnProtectionApp(item);
            const disabledApp = this.getDisabledApp(item);

            let realProtectionApp = null;
            let apps: any;

            if (item?.processed?.productsIncompatible) {
                apps = this.valuesService.boxApps;
            } else {
                apps = new Set(this.valuesService.compatibleProtectionApps[deviceOs]);
            }

            let subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.PROTECTION;
            if (this.noCompatibleServices(apps)) {
                if (item?.processed?.productsIncompatible) {
                    subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.NO_PROTECTION_IOT;
                } else {
                    subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.ALL_EXPIRED;
                }
            } else {
                if (!protectionApp) {
                    if (this.allCompatibleSlotsOccupied(apps)) {
                       subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.NO_FREE_SLOTS;
                    } else {
                        if (item?.processed?.productsIncompatible) {
                            subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.NO_PROTECTION_IOT;
                        } else {
                            if (this.agentNotInstalled(item)) {
                                subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.NO_PROTECTION_NO_AGENT_FREE_SLOTS;
                            } else if (disabledApp) {
                                subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.NO_PROTECTION_AGENT_FREE_SLOTS;
                            } else {
                                // cazul default
                                subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.SLOT_NOT_FOUND;
                            }
                        }
                    }
                } else {
                    if (validProtection) {
                        realProtectionApp = protectionApp;
                        if (protectionApp === this.valuesService.appAVFREE) {
                            subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.AVFREE_PROTECTION;
                        } else if (protectionApp === this.valuesService.appBIP && item?.protection_status?.issues === 0) {
                            subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.PAID_WEB_PROTECTION;
                        } else if (protectionApp === this.valuesService.appBIS) {
                            subscriptionStatus = this.webProtectionStatus(item);
                            if (subscriptionStatus === this.devicesValuesService.deviceStateBasedOnSubscriptions.PAID_WEB_PROTECTION) {
                                realProtectionApp = this.valuesService.appBIP;
                            }
                        } else {
                            subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.PROTECTION;
                        }
                    } else if (this.allCompatibleSlotsOccupied(apps)) {
                        subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.NO_FREE_SLOTS;
                    } else {
                        // cazul default
                        subscriptionStatus = this.devicesValuesService.deviceStateBasedOnSubscriptions.SLOT_NOT_FOUND;
                    }
                }
            }

            if (realProtectionApp) {
                item.processed.protectionApp = realProtectionApp;
            }
            item.processed.subscriptionStatus = subscriptionStatus;
            item.processed.disabledApp = disabledApp;
        },

        /**
         * app installed on this device and other compatible apps
         * Daca avem un cont de soho, atunci se iau in calcul conditiile under soho si se exclud celelalte, la fel si la box. soho are prioritate fata de box, box fata de cl.
         * La box, daca tine de acelasi bundle
         * Daca avem un box separat si un cl separat atunci fiecare joaca dupa regulile lui
         */
        addInstalledAppsFlags: item => {
            let hasProtection       = false;
            let freeProtection      = false;
            const installedApps     = [];
            const compatibleApps    = [];
            const deviceOs          = item.device_os;
            const deviceArch        = item?.device_os_arch;
            // set in care pun categoriile de aplicatii disponibile, trebuie in special pt omniture
            const appCategories     = new Set();

            for (const type in this.valuesService.protectionAppsPriority) {
                const apps = this.getPossibleDevicesApps(item, type);
                if (!apps) {
                    continue;
                }

                if (type === ProductsToInstall.PROTECTION) {
                    const appId = this.getInstalledProtectionAppId(apps, item);
                    if (!appId) {
                        // adaug app-ul compatibil doar daca am voie
                        const compatibleAppInfo = this.getCompatibleAppInfo(apps[0], deviceOs, deviceArch, item);
                        if (compatibleAppInfo) {
                            compatibleApps.push(compatibleAppInfo);
                        }
                    } else {
                        if (appId && !this.valuesService.boxApps.has(appId)) {
                            const appInfo = this.getInstalledProtectionAppInfo(appId, item);
                            hasProtection = true;
                            freeProtection = this.valuesService.freeProtectionApps.has(appId) || appInfo.level === this.valuesService.appLevels.LITE;
                            if (this.appsConfigService.showApp(appId)) {
                                installedApps.push(appInfo.info);
                            }
                            appCategories.add(type);
                        }
                    }
                } else {
                    for (const appId of apps) {
                        const installedAppId = this.getInstalledAppId(appId, item);
                        if (!installedAppId) {
                            const compatibleAppInfo = this.getCompatibleAppInfo(appId, deviceOs, deviceArch, item);
                            if (compatibleAppInfo) {
                                compatibleApps.push(compatibleAppInfo);
                            }
                        } else {
                            const appInfo = this.getInstalledAppInfo(appId);
                            if (this.appsConfigService.showApp(appId)) {
                                installedApps.push(appInfo);
                            }
                            appCategories.add(type);
                        }
                    }
                }
            }

            item.processed.hasProtection       = hasProtection;
            item.processed.noProducts          = !installedApps.length;
            item.processed.freeProtection      = freeProtection;
            item.processed.hasBoxProtectionApp = this.processingMethods.hasBoxProtectionApp(item);
            item.processed.installedApps       = installedApps;
            item.processed.compatibleApps      = compatibleApps;
            item.processed.appCategories       = appCategories;
            item.processed.hasParental         = this.deviceHasParental(item);
            item.processed.hasParentalNCC      = this.deviceHasParentalNCC(item);
            item.processed.hasVPN              = this.deviceHasVPN(item);
        },

        hasBoxProtectionApp: item => {
            if (!this.checkMethods.isTempBoxManaged(item)) {
                return false;
            }

            if (this.valuesService.boxApps.has(item?.box_app_id) && this.protectionIsValid(item?.box_app_id, item.box_device_id)) {
                return true;
            }

            return false;
        },

        attachProfileToDevice: device => {
            const auxProfile = this.profilesService.getProfileById(device.profile_id);
            const profile = {};

            if (this.checkMethods.isBox(device)) {
                return;
            }

            if (!auxProfile) {
                profile['profile_name'] = '';
                device.processed.hasProfile = false;
                device.processed.profyle_type = undefined;
                device.processed.profile_id = undefined;
                return;
            }

            profile['profile_id'] = device.profile_id;
            profile['profile_name'] = (auxProfile.first_name !== undefined ? auxProfile.first_name : '');
            profile['profile_pic'] = auxProfile.profile_pic;
            profile['bgColor'] = auxProfile.bgColor;
            profile['type'] = auxProfile.type;

            device.processed.device_profile = profile;
            device.processed.hasProfile = device.processed.device_profile && !this.utilsService.isEmptyObject(device.processed.device_profile);
            device.processed.profyle_type = device.processed.device_profile ? device.processed.device_profile.type : undefined;
            device.processed.profile_id = device.processed.device_profile ? device.processed.device_profile.profile_id : undefined;
        }
    };

    getCompatibleAppInfo(appId, deviceOs, deviceArch, item) {
        if ((!this.appsDisplayRules.hasOwnProperty(appId)
                || this.appsDisplayRules[appId].display(item))
            && this.appsConfigService.showAppForOs(appId, deviceOs)
            && this.appsConfigService.showAppForArchitecture(appId, deviceOs, deviceArch)) {
            return this.productsConfigService.getDevicesAppInfo(appId);
        }
        return null;
    }

    getInstalledAppInfo(appId) {
        const appInfo: any = {...this.productsConfigService.getDevicesAppInfo(appId)};
        let getFriendlyNameParam: any = {
            app_id: appId
        };
        appInfo.appName = this.productsConfigService.getAppName(getFriendlyNameParam);
        return appInfo;
    }

    getInstalledProtectionAppInfo(appId, item) {
        const appLevel = this.getProtectionLevelFromSubscriptions(appId, item.device_id);
        let appInfo: any = {};
        if (appId === this.valuesService.appCL && appLevel === this.valuesService.appLevels.LITE) {
            appInfo = {...this.productsConfigService.getDevicesAppInfo(this.valuesService.appAVFREE)};
        } else {
            appInfo = {...this.productsConfigService.getDevicesAppInfo(appId)};
        }

        const getFriendlyNameParam = {
            app_id: appId,
            app_params : {
                level: appLevel
            }
        };
        appInfo.appName = this.productsConfigService.getAppName(getFriendlyNameParam);
        return { info: appInfo, level: appLevel };
    }

    getPossibleDevicesApps(item, type) {
        let apps: any = this.valuesService.protectionAppsPriority[type];
        // daca nu e array, inseamna ca e separat in functie de os
        if (!Array.isArray(apps)) {
            const deviceOsBasedOnProtectionApp = this.getDeviceOsBasedOnProtectionApp(item);
            apps = this.valuesService.protectionAppsPriority[type][deviceOsBasedOnProtectionApp];
        }
        return apps;
    }


    webProtectionStatus(item) {
        // Check if device has paid protection installed
        const apps = item?.apps ? item.apps : [];
        for (const app of apps) {
            if (app.app_id === this.valuesService.appBIP) {
                if (app.state !== this.devicesValuesService.appState.IS_INSTALLED) {
                    return this.devicesValuesService.deviceStateBasedOnSubscriptions.FREE_WEB_PROTECTION;
                } else {
                    if (this.protectionIsValid(this.valuesService.appBIP, item.device_id)) {
                        return this.devicesValuesService.deviceStateBasedOnSubscriptions.PAID_WEB_PROTECTION;
                    } else {
                        return this.devicesValuesService.deviceStateBasedOnSubscriptions.PAID_WEB_PROTECTION_NO_SLOT;
                    }
                }
            }
        }

        // Check if user has paid subscription but the device is not linked to it
        const servicesList = this.subscriptionsService.getAllFilteredServices();
        for (const service of servicesList) {
            if (service.bundle_id === this.valuesService.appBIP) {
                return this.devicesValuesService.deviceStateBasedOnSubscriptions.PAID_WEB_PROTECTION_NO_SLOT;
            }
        }

        return this.devicesValuesService.deviceStateBasedOnSubscriptions.FREE_WEB_PROTECTION;
    }


    deviceHasParental(item) {
        const installedApps = item?.processed?.installed_apps ? item?.processed?.installed_apps : [];

        for (const appId of installedApps) {
            if (appId === this.valuesService.appPA) {
                return true;
            }
        }
        return false;
    }

    deviceHasEnablePA(deviceId) {
        const device = this.getDevicesObject()?.[deviceId];
        if (device?.apps) {
            for (const app of device?.apps) {
                if (app?.app_id === this.valuesService.appPA && app?.enable === 1) {
                    return true;
                }
            }
        }
        return false;
    }

    deviceHasParentalNCC(item) {
        const installedApps = item?.processed?.installed_apps ? item?.processed?.installed_apps : [];

        for (const appId of installedApps) {
            if (appId === this.valuesService.appPANCC) {
                return true;
            }
        }
        return false;
    }

    deviceHasVPN(item) {
        const installedApps = item?.processed?.installed_apps ? item?.processed?.installed_apps : [];
        for (const appId of installedApps) {
            if (appId === this.valuesService.appVPN) {
                return true;
            }
        }
        return false;
    }

    deviceHasSoho(item) {
        const filteredServices = [];
        const services = item?.processed?.services ? item?.processed?.services : [];
        if (!services.length) {
            return false;
        }

        for (const service of services) {
            if (!this.valuesService.sohoBundles.includes(service.bundle_id)) {
                filteredServices.push(service);
            }
        }

        return !filteredServices?.length;
    }

    /**
     * Get device OS if it is recognized or find it based on protection app installed if installed
     * @param device
     * @param protectionApp
     * @returns deviceOs
     */
    getDeviceOsBasedOnProtectionApp(device) {
        const protectionApp = device?.protection_status?.protection_app;
        let deviceOs = device.device_os;

        if (!this.valuesService.compatibleProtectionApps[deviceOs] && protectionApp) {
            for (const os in this.valuesService.compatibleProtectionApps) {
                if (this.valuesService.compatibleProtectionApps[os].indexOf(protectionApp) > -1) {
                    deviceOs = os;
                }
            }
        }
        return deviceOs;
    }

    noCompatibleServices(apps) {
        const servicesList = this.subscriptionsService.getAllFilteredServices();
        const compatibleServices = [];
        for (const service of servicesList) {
            const applications = service.applications;
            for (const app of applications) {
                if (apps.has(app.app_id)) {
                    compatibleServices.push(service);
                }
            }
        }

        if (!compatibleServices.length) {
            return true;
        }

        for (const service of compatibleServices) {
            if (service.status !== this.valuesService.EXPIRED) {
                return false;
            }
        }

        return true;
    }

    serviceHasFreeSlots(service) {
        if (!service) {
            return false;
        }

        const totalSlots = service.slots;
        let activeSlots = service.active_slots;

        if (this.valuesService.boxBundles.has(service.bundle_id)) {
            let foundBoxSlot = false;
            if (service?.accounts) {
                for (const account of service.accounts) {
                    if (this.profilesService.getOwnerEmail() !== account.email || !account?.usage_summary) {
                        continue;
                    }
                    for (const summary of account.usage_summary) {
                        const device = this.retrieveTempDeviceById(summary.slot_id);
                        if (this.checkMethods.isBox(device)) {
                            foundBoxSlot = true;
                        }
                    }
                }
            }

            if (!foundBoxSlot) {
                activeSlots++;
            }
        }

        if (totalSlots > activeSlots) {
            return true;
        }

        return false;
    }

    allCompatibleSlotsOccupied (apps) {
        const servicesList = this.subscriptionsService.getAllFilteredServices();
        const compatibleServices = [];
        for (const service of servicesList) {
            const applications = service.applications;
            for (const app of applications) {
                if (apps.has(app.app_id) && service.status !== this.valuesService.EXPIRED) {
                    compatibleServices.push(service);
                }
            }
        }

        if (!compatibleServices.length) {
            return false;
        }

        for (const service of compatibleServices) {
            if (this.serviceHasFreeSlots(service)) {
                return false;
            }
        }

        return true;
    }

    getDisabledApp(device) {
        const deviceOs = this.getDeviceOsBasedOnProtectionApp(device);
        if (deviceOs === OperatingSystems.WINDOWS || deviceOs === OperatingSystems.OSX || deviceOs === OperatingSystems.WINDOWS_SERVER) {
            return this.valuesService.compatibleProtectionApps[deviceOs][0];
        }

        return null;
    }

    agentNotInstalled(device) {
        const deviceOs = this.getDeviceOsBasedOnProtectionApp(device);
        return device.agent_status !== this.valuesService.agentStatus.INSTALLED
                || deviceOs === OperatingSystems.ANDROID
                || deviceOs === OperatingSystems.IOS;
    }

    protectionIsValid (appId, deviceId) {
        const servicesList = this.subscriptionsService.getAllServices();
        for (const service of servicesList) {
            if (service.status === this.valuesService.EXPIRED) {
                continue;
            }

            const accounts = service.accounts;
            if (!accounts) {
                continue;
            }

            for (const account of accounts) {
                if (this.profilesService.getOwnerEmail() !== account.email || !account?.usage_summary) {
                    continue;
                }
                const usageSummary = account.usage_summary;
                for (const summary of usageSummary) {
                    if (summary.slot_id === deviceId && summary.app_id === appId) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * Gets app level from subscriptions because in some cases you do not have subscriptions info in device list
     * but you might have it subscriptions
     * @param appId App id
     * @param deviceId Device id
     * @returns App level or undefined if it has no app level
     */
    getProtectionLevelFromSubscriptions(appId, deviceId) {
        const servicesList = this.subscriptionsService.getAllServices();
        let foundService = null;
        for (const service of servicesList) {
            const accounts = service.accounts;
            if (!accounts) {
                continue;
            }
            for (const account of accounts) {
                if (this.profilesService.getOwnerEmail() !== account.email || !account?.usage_summary) {
                    continue;
                }
                const usageSummary = account.usage_summary;
                for (const summary of usageSummary) {
                    if (summary.slot_id === deviceId && summary.app_id === appId) {
                        foundService = service;
                        break;
                    }
                }
                if (foundService) {
                    break;
                }
            }
            if (foundService) {
                const applications = service.applications;
                for (const app of applications) {
                    if (app.app_id === appId) {
                        return app?.app_params?.level;
                    }
                }
                break;
            }
        }
        return null;
    }


    sortMethods = {
        // - At Risk
        // - Protected
        // - Disconnected
        devicesByStatus: devicesList => {
            const atRiskStatus = [];
            const protectedStatus = [];
            const disconnectedStatus = [];

            if (!devicesList) {
                return [];
            }

            for (const device of devicesList) {
                if (device.processed.protectionStatus === this.valuesService.protectionStatus.AT_RISK) {
                    atRiskStatus.push(device);
                } else if (device.processed.protectionStatus === this.valuesService.protectionStatus.PROTECTED) {
                    protectedStatus.push(device);
                } else {
                    disconnectedStatus.push(device);
                }
            }

            const orderedDevices = [
                atRiskStatus,
                protectedStatus,
                disconnectedStatus
            ];

            // sortare alfabetica + concatenare
            let orderedArray = [];
            for (const devicesArray of orderedDevices) {
                devicesArray.sort(
                    (device1, device2) => {
                        const localCompareResp = device1.display_name.localeCompare(device2.display_name);
                        if (localCompareResp === 0 && device1.owner_name && device2.owner_name) {
                            return device1.owner_name.localeCompare(device2.owner_name);
                        }
                    }
                );
                orderedArray = orderedArray.concat(devicesArray);
            }
            return orderedArray;
        },

        devicesByStatusObject: devicesList => {
            const atRiskStatus = [];
            const protectedStatus = [];
            const disconnectedStatus = [];

            if (!devicesList) {
                return {};
            }

            for (const device of devicesList) {
                if (device.processed.protectionStatus === this.valuesService.protectionStatus.AT_RISK) {
                    atRiskStatus.push(device);
                } else if (device.processed.protectionStatus === this.valuesService.protectionStatus.PROTECTED) {
                    protectedStatus.push(device);
                } else {
                    disconnectedStatus.push(device);
                }
            }

            const orderedDevices: DevicesByStatus = {
                atRiskStatus,
                protectedStatus,
                disconnectedStatus
            };

            // sortare alfabetica + concatenare
            for (const key in orderedDevices) {
                orderedDevices[key] = orderedDevices[key].sort(
                    (device1, device2) => device1.display_name.localeCompare(device2.display_name)
                );
            }
            return orderedDevices;
        }
    };

    // filters the list of devices
    processDevices() {
        this.tempDevices.box_managed = [];
        this.tempDevices.wob = []; //lista de devices outside box
        this.tempDevices.iot = []; //lista device-urilor IoT
        this.tempDevices.box_managed_sorted = {}; //impartirea device-urilor per box
        this.tempDevices.parental = []; // este folosit in iparental.service
        this.tempDevices.protectionAppInstalled = [];  // este folosit in data.resolver si onboarding
        this.tempDevices.mobile = []; // lista de devices cu type=phone/tablet; este folosit in recommendations cards
        this.tempDevices.clones = []; // lista device-urilor care au clone

        for (const currentDevice of this.tempDevices.all) {
            if (!currentDevice.processed) {
                currentDevice.processed = {};
            }

            this.processingMethods.addAccounts(currentDevice);
            this.processingMethods.addIotFlag(currentDevice);
            this.processingMethods.addProductsIncompatible(currentDevice);
            this.processingMethods.addCrc(currentDevice);
            this.processingMethods.addClVersion(currentDevice);

            this.processingMethods.addBoxManagedFlag(currentDevice);


            this.processingMethods.addConnectivityFlags(currentDevice);
            this.processingMethods.addInstalledApps(currentDevice);

            this.processingMethods.addSubscriptionsFlag(currentDevice);
            this.processingMethods.addSubscriptions(currentDevice);

            this.processingMethods.addInstalledAppsFlags(currentDevice);

            this.processingMethods.addDeviceProtectionStatus(currentDevice);

            this.processingMethods.attachProfileToDevice(currentDevice);
            this.processingMethods.addSettings(currentDevice);
            this.processingMethods.addBoxProtectionStatus(currentDevice);

            if (this.checkMethods.hasProtectionAppInstalled(currentDevice)) {
                this.tempDevices.protectionAppInstalled.push(currentDevice);
            }


            if (currentDevice.processed.box_managed && !this.checkMethods.isBox(currentDevice)) {
                this.tempDevices.box_managed.push(currentDevice);
                this.tempDevices.parental.push(currentDevice);
                this.pushToSpecificBoxArrayOfDevices(currentDevice);
            }

            if (!currentDevice.processed.box_managed && !this.checkMethods.isBox(currentDevice)) {
                this.tempDevices.wob.push(currentDevice);
                this.tempDevices.parental.push(currentDevice);
            }

            if (this.checkMethods.isIoT(currentDevice)) {
                this.tempDevices.iot.push(currentDevice);
            }

            if (this.checkMethods.hasClones(currentDevice)) {
                this.tempDevices.clones.push(currentDevice);
            }

            if (this.devicesValuesService.mobileDevices.has(currentDevice.device_type)) {
                this.tempDevices.mobile.push(currentDevice);
            }
        }

        for (const box of this.tempDevices.box) {
            this.processingMethods.addBoxCounters(box);

            if (this.valuesService.availableDevices[box.device_model] && this.valuesService.availableDevices[box.device_model].hasCustomBuyLink) {
                this.configService.config.hasCustomBuyLink = true;
            }
        }
    }

    copyArray(arrayKey) {
        const newArray = [];
        for (const tempDevice of this.tempDevices[arrayKey]) {
            const deviceId = tempDevice.device_id;
            newArray.push(this.retrieveDeviceByIdByIteration(deviceId));
        }
        this.devices[arrayKey] = newArray;
    }

    copyObject(objectKey) {
        const newObject = {};
        for (const deviceCrc in this.tempDevices[objectKey]) {
            const deviceId = this.tempDevices[objectKey][deviceCrc].device_id;
            newObject[deviceCrc] = this.retrieveDeviceByIdByIteration(deviceId);
        }
        this.devices[objectKey] = newObject;
    }

    copyFromTempToFinalDevices() {
        this.devices.all = [...this.tempDevices.all];
        this.devices.count = this.tempDevices.count;

        this.copyArray('box_managed');
        this.copyArray('wob');
        this.copyArray('box');
        this.copyArray('iot');
        this.copyArray('parental');
        this.copyArray('clones');
        this.copyArray('protectionAppInstalled');
        this.copyArray('mobile');

        this.copyObject('allObject');
        this.copyObject('crcObject');

        const boxManagedSorted = {};
        for (const boxDeviceId in this.tempDevices.box_managed_sorted) {
            const deviceArray = [];
            for (const tempDevice of this.tempDevices.box_managed_sorted[boxDeviceId]) {
                const deviceId = tempDevice.device_id;
                deviceArray.push(this.retrieveDeviceById(deviceId));
            }
            boxManagedSorted[boxDeviceId] = deviceArray;
        }
        this.devices.box_managed_sorted = boxManagedSorted;
    }

    pushToSpecificBoxArrayOfDevices(currentDevice) {
        if (currentDevice.processed.box_managed) {
            if (!this.tempDevices.box_managed_sorted[currentDevice.box_device_id]) {
                this.tempDevices.box_managed_sorted[currentDevice.box_device_id] = [];
            }
            this.tempDevices.box_managed_sorted[currentDevice.box_device_id].push(currentDevice);
        }
    }

    getNrOfDevicesWithInstalledProtection() {
        //in onboarding-modal si data.resolver
        return this.devices && this.devices.protectionAppInstalled ? this.devices.protectionAppInstalled.length : 0;
    }

    /**
     * Gets the flag for automatic upgrade for a device
     * @param deviceId The gievn device id
     * @returns {boolean} The flag for automatic upgrade
     */
    public getDeviceAutomaticUpgrade(deviceId): boolean {
        const box = this.retrieveDeviceById(deviceId);
        const automaticUpgrade = box?.processed?.device_settings?.[this.valuesService.appBOX2]?.[this.valuesService.deviceSettings.automaticUpgrade];
        return !!automaticUpgrade;
    }

    computeBoxIds(devices) {
        for (const device of devices) {
            if (this.checkMethods.isBox(device)) {
                this.boxIds.push(device.device_id);
            }
        }
    }

    /**
     * Checks if there are network equipment devices
     * @returns {boolean} True if there are network equipment devices
     */
    public hasNetworkEquipment() {
        return this.boxIds.length > 0;
    }

    getToggledInternet(deviceId) {
        const device = this.devices.allObject[deviceId];
        const traffic = device.traffic.blocked;
        if (traffic === this.devicesValuesService.internet.PAUSED) {
            return this.devicesValuesService.internet.UNPAUSED;
        }
        return this.devicesValuesService.internet.PAUSED;
    }

    toggleInternet(deviceId) {
        const device = this.devices.allObject[deviceId];
        if (!device) {
            const err = new Error('malformed');
            throwError(() => err);
        }

        if (!device.traffic) {
            device.traffic = {
                blocked: this.devicesValuesService.internet.UNPAUSED
            };
        }

        const toggledInternet = this.getToggledInternet(deviceId);

        return this.connectMgmtService.blockDevice(deviceId, toggledInternet)
        .pipe(
            map(resp => {
                if (resp) {
                    device.traffic.blocked = toggledInternet;
                    return toggledInternet;
                } else {
                    throw(resp);
                }
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    setBoxOfflineWithoutTrafficReport(box) {
        if (box.box_online_ts && Math.ceil((new Date().getTime() - box.box_online_ts) / 1000 ) > 7200) {
            box.processed.boxOffline = true;
        } else {
            box.processed.boxOffline = false;
        }
    }

    setBoxOfflineBasedOnTrafficReport(device) {
        const now = new Date().getTime() / 1000;
        const traffic = this.traffic[device.device_id] ? this.traffic[device.device_id]: {};
        for (const i in traffic) {
            const trafficSeconds = traffic[i].timestamp / 1000;

            // daca BOX are traffic in ultimele doua ore nu e offline
            if (now - trafficSeconds < (2 * 60 * 60) && (traffic[i].in > 0 || traffic[i].out > 0)) {
                device.processed.boxOffline = false;
                break;
            }
        }
    }

    setBoxOfflineFlag(device, count): Observable<any> {
        if (!device.processed) {
            device.processed = {};
        }

        this.processingMethods.addBoxVersion(device);
        // dupa ce se rezolva bug-ul in connect legat de box_online_ts atunci putem tine cont doar de el fara sa mai facem requestul de trafic
        // daca e v1, se verifica box_online_ts (daca exista, setam processed.boxOffline) si face return of(false)
        this.setBoxOfflineWithoutTrafficReport(device);

        if (device.processed.box_version !== this.devicesValuesService.boxVersion.BOX2) {
            return of(true);
        }

        // daca nu e nevoie de request in connect, tot trebuie adaugata informatia pe device in processed
        if ((!this.markToUpdateDevicesTraffic && !this.trafficChangedIds.has(device.device_id)) || this.trafficInProgress.has(device.device_id)) {
            this.setBoxOfflineBasedOnTrafficReport(device);
            return of(true);
        }

        // daca e v2 si device.processed.boxOffline este undefined (asta inseamna ca nu s-a facut requestul de trafic), facem request
        // box_online_ts uneori zice ca a fost conectat > 2h desi el are trafic in ultimele 2h
        this.trafficInProgress.add(device.device_id);
        return this.connectMgmtService.getTrafficReport(device.device_id, count)
        .pipe(
            map(resp => {
                if (resp.traffic) {
                    this.traffic[device.device_id] = resp.traffic;
                    this.setBoxOfflineBasedOnTrafficReport(device);
                }
                this.trafficInProgress.delete(device.device_id);
                return true;
            }),
            catchError(() => {
                this.trafficChangedIds.add(device.device_id);
                this.trafficInProgress.delete(device.device_id);
                return of(true);
            })
        );
    }

     //creates an object with device_id as keys, for easy filter?
     putDeviceIdAsKey(resp) {
        for (const device of resp) {
            this.tempDevices.allObject[device.device_id] = device;
        }
    }

    populateExceptions(deviceExceptions) {
        const exceptions = {
            all: [],
            object: {}
        };
        for (const expOnBox of deviceExceptions) {
            if (expOnBox.length > 0) {
                const boxDeviceId = expOnBox[0].box_device_id;
                exceptions.object[boxDeviceId] = {};
                for (const exception of expOnBox) {
                    const deviceId = exception?.device_id;
                    const device = this.retrieveDeviceById(deviceId);
                    exception.device_name = device?.display_name ?? 'N/A';
                    const exceptionId = exception.exception_id;
                    exceptions.object[boxDeviceId][exceptionId] = exception;
                }

                exceptions.all = exceptions.all.concat(expOnBox);
            }
        }
        return exceptions;
    }

    noListing() {
        return !this.configService.getDevices()
            && !this.appsConfigService.showApp(this.valuesService.appPA)
            && !this.appsConfigService.showApp(this.valuesService.appPANCC);
    }

    listDevicesOnly(): Observable<any> {
        if (this.noListing() || !this.markToUpdateDevices) {
            return of(this.devices);
        }

        if (this.onlistDevices$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistDevices$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistDevices$.next(this.valuesService.processServiceState.INPROGRESS);
            this.tempDevices.error = false;
            this.tempDevices.all = [];
            this.tempDevices.allObject = {};
            this.tempDevices.count = 0;
            this.boxIds = [];
            let request: Observable<any>;
            if (this.profilesService.getCurrentGroup()) {
                request = this.listDevicesGroupContext({page: 0});
            } else {
                request = this.listDevicesPersonalContext();
            }
            return request
            .pipe(
                map(() => {
                    if (this.tempDevices?.error) {
                        this.markToUpdateDevices = true;
                    } else {
                        this.markToUpdateDevices = false;
                    }
                    this.onlistDevices$.next(this.valuesService.processServiceState.DONE);
                    return this.tempDevices;
                }),
                catchError(err => {
                    this.tempDevices.error = true;
                    this.markToUpdateDevices = true;
                    this.onlistDevices$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    private listOnePagePersonalContext(page: number): Observable<any> {
        return this.connectMgmtService.listDevices({page})
        .pipe(
            map((resp: any) => {
                if (resp) {
                    if (resp.devices && resp.count) {
                        this.tempDevices.count = resp.count;
                        this.tempDevices.all = this.tempDevices.all.concat(resp.devices);

                        this.putDeviceIdAsKey(resp.devices);
                        this.computeBoxIds(resp.devices);
                    }
                    this.atLeastOneSuccessfulDevicesRequest = true;
                    return true;
                } else {
                    throw new Error('malformed_request');
                }
            }),
            catchError(_err => {
                this.tempDevices.error = true;
                return of(false);
            })
        );
    }

    private listDevicesPersonalContext(): Observable<any> {
        return this.listOnePagePersonalContext(0)
        .pipe(
            mergeMap(() => {
                const devicesRequests = this.computeTheRestOfPagesToBeListed();
                return forkJoin(
                    devicesRequests
                );
            })
        );
    }

    private listDevicesGroupContext(extraParams: ListDevicesV2ExtraParams): Observable<any> {
        return this.connectMgmtService.listDevices(extraParams)
        .pipe(
            mergeMap((resp: any) => {
                if (resp) {
                    if (resp.devices) {
                        this.tempDevices.all = this.tempDevices.all.concat(resp.devices);
                        this.tempDevices.count = this.tempDevices.all.length;

                        this.putDeviceIdAsKey(resp.devices);
                        this.computeBoxIds(resp.devices);
                    }
                    this.atLeastOneSuccessfulDevicesRequest = true;

                    if (resp.devices.length < this.valuesService.devicesPerRequest || !resp?.cursor?.token) {
                        return of(true);
                    }
                    return this.listDevicesGroupContext({page: extraParams.page + 1, cursor: resp?.cursor?.token});
                } else {
                    throw new Error('malformed_request');
                }
            }),
            catchError(_err => {
                this.tempDevices.error = true;
                return of(false);
            })
        );
    }


    populateBoxArray() {
        //lista de boxuri
        this.tempDevices.box = [];
        for (const currentDevice of this.tempDevices.all) {
            if (!currentDevice.processed) {
                currentDevice.processed = {};
            }
            if (this.checkMethods.isBox(currentDevice)) {
                this.tempDevices.box.push(currentDevice);
            }
        }
    }

    listDevices(): Observable<any> {
        if (this.onlistDevicesOnly$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistDevicesOnly$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onlistDevicesOnly$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.listDevicesOnly()
            .pipe(
                mergeMap(() => {
                    this.populateBoxArray();
                    return forkJoin({
                        // requesturi independente, ale caror informatii nu depind unele de altele
                        offlineFlags: this.setOfflineFlagForAllDevices(),
                        deviceSettings: this.listDevicesSettings()
                    });
                }),
                map(() => {
                    this.processDevices();
                    this.copyFromTempToFinalDevices();
                    this.onlistDevicesOnly$.next(this.valuesService.processServiceState.DONE);
                    return true;
                }),
                catchError(() => {
                    this.copyFromTempToFinalDevices();
                    this.onlistDevicesOnly$.next(this.valuesService.processServiceState.DONE);
                    return of(true);
                })
            );
        }
    }

    showDisconnectedFilter() {
        return !!(this.subscriptionsService.hasBox() && this.devices.box.length);
    }

    listDevicesSettings(): Observable<any> {
        return this.settingsService.listDeviceSettings(this.getTempDevicesListArray())
        .pipe(
            map(() => {
                if (this.settingsService.devicesNeedUpdate() || this.atLeastOneSuccessfulDevicesRequest) {
                    this.settingsService.deleteOldSettings();
                }
                return true;
            }),
            catchError(() => of(true))
        );
    }

    private computeTheRestOfPagesToBeListed(): Record<string, Observable<any>> {
        const nrPages = Math.ceil(this.tempDevices.count / this.valuesService.devicesPerRequest);
        // in case there are no more requests to be made
        // it still needs an obsevable to continue logic in a more simple manner
        const devicesRequests: Record<string, Observable<any>> = { '0': of(true)};
        for (let pageIndex = 1; pageIndex < nrPages; pageIndex++) {
            devicesRequests[pageIndex.toString()] = this.listOnePagePersonalContext(pageIndex);
        }
        return devicesRequests;
    }

    setOfflineFlagForAllDevices(): Observable<any> {
        const requests = {'0': of(true)};
        let counter = 1;
        for (const currentDevice of this.tempDevices.box) {
            requests[counter.toString()] = this.setBoxOfflineFlag(currentDevice, counter);
            counter++;
        }

        return forkJoin(requests).pipe(
            map(() => {
                this.trafficChangedIds.clear();
                this.markToUpdateDevicesTraffic = false;
                return true;
            }),
            catchError(() => of(true))
        );
    }

    updateAllTraffic() {
        this.markToUpdateDevicesTraffic = true;
    }

    updateDeviceTraffic(deviceId) {
        const device = this.retrieveDeviceById(deviceId);
        if (this.checkMethods.isBox(device)) {
            this.trafficChangedIds.add(deviceId);
        } else {
            this.updateDevices();
        }
    }

    listExceptions(): Observable<any> {
        if (!this.markToUpdateExceptions || this.boxIds.length === 0) {
            return of(this.exceptions);
        }

        if (this.onlistExceptions$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistExceptions$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistExceptions$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectMgmtService.listExceptions(this.boxIds)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        this.exceptions = this.populateExceptions(resp);
                        this.markToUpdateExceptions = false;
                        this.onlistExceptions$.next(this.valuesService.processServiceState.DONE);
                        return true;
                    } else {
                        this.markToUpdateExceptions = true;
                        this.onlistExceptions$.next(this.valuesService.processServiceState.DONE);
                        return of('malformed_request');
                    }
                }),
                catchError(err => {
                    this.markToUpdateExceptions = true;
                    this.onlistExceptions$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    enableApp(deviceId, appId) {
        return this.appMgmtService.enableApp(deviceId, appId)
        .pipe(
            map(resp => {
                const apps = this.devices?.allObject?.[deviceId]?.apps ?? [];
                for (const app of apps) {
                    if (app?.app_id === appId) {
                        app.enable = AppEnableStatus.ON;
                        this.messageService.sendMessage(this.valuesService.events.triggerStart, {});
                        break;
                    }
                }
                return resp;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Sets a url as exception for the given threat id and device or box id
     * @param data The data to be sent to the backend
     * @returns {Observable<any>} An observable that resolves to true if the request was successful, false otherwise
     */
    public setException(data): Observable<any> {
        return this.connectMgmtService.setException(data)
        .pipe(
            map(resp => {
                this.updateExceptions();
                this.messageService.sendMessage(this.valuesService.events.triggerStart, {});
                return resp;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    getDevicesNr() {
        return this.devices.count;
    }

    getExceptionsList() {
        return this.exceptions;
    }

    /**
     * Returns the exception object for a given threat id
     * @param threatId The threat id to search for
     * @returns {object|null} An exception object if found, null otherwise
     */
    public getException(threatId): object|null {
        const exceptions = this.exceptions?.all ?? [];
        for (const exception of exceptions) {
            if (exception?.threat_id === threatId) {
                return exception;
            }
        }
        return null;
    }

    checkListDevicesHasError() {
        if (this.devices.error) {
            return true;
        }
        return false;
    }

    getDevicesList() {
        return this.devices;
    }

    getParentalDevicesList() {
        return this.devices.parental ? this.devices.parental : [];
    }

    getDevicesListArray() {
        return this.devices.all;
    }

    getTempDevicesListArray() {
        return this.tempDevices.all;
    }

    getDevicesObject() {
        return this.devices.allObject;
    }

    getBoxesNumber() {
        return this.devices?.box?.length;
    }

    getParentalDevices() {
        return this.utilsCommonService.computeObject(this.devices.parental);
    }

    getMobileDevices() {
        return this.devices.mobile ? this.devices.mobile : [];
    }

    updateDevices() {
        if (this.onlistDevices$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateDevices = true;
        }
    }

    devicesIsStillInProgress() {
        return this.onlistDevices$.value === this.valuesService.processServiceState.INPROGRESS;
    }

    updateExceptions() {
        if (this.onlistExceptions$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateExceptions = true;
        }
    }

    //find device by id
    retrieveDeviceByCrc(deviceCrc) {
        if (!deviceCrc) {
            return null;
        }

        try {
            const stringCrc = deviceCrc.toString();
            return this.devices?.crcObject?.[stringCrc] ?? null;
        } catch {
            return null;
        }
    }

    retrieveDeviceById(deviceId) {
        return this.devices?.allObject?.[deviceId] ?? null;
    }

    retrieveDeviceByIdByIteration(deviceId) {
        const devices = this.devices.all;
        if (devices) {
            const d = devices.filter(device => device.device_id === deviceId);
            return (d.length !== 0 ? d[0] : null);
        } else {
            return null;
        }
    }

    retrieveTempDeviceById(deviceId) {
        const devices = this.tempDevices.all;
        if (devices) {
            const d = devices.filter(device => device.device_id === deviceId);
            return (d.length !== 0 ? d[0] : null);
        } else {
            return null;
        }
    }

    checkDeviceAndCloseModal(deviceId) {
        if (deviceId) {
            const device = this.retrieveDeviceById(deviceId);
            if (!device) {
                this.modalRoutelessService.close();
                return true;
            }
        }
        return false;
    }

    resetInProgressFlags() {
        this.onlistDevices$.next(this.valuesService.processServiceState.DONE);
        this.onlistDevicesOnly$.next(this.valuesService.processServiceState.DONE);
    }

    /**
     * Gets the antitheft app id based on the device os
     * @param {DeviceModel} device The given device object
     * @returns {string} The antitheft app id
     */
    public getTheAntitheftAppId(device: DeviceModel): string {
        switch (device.device_os) {
            case OperatingSystems.ANDROID:
                return this.valuesService.appBMS;
            case OperatingSystems.IOS:
                return this.valuesService.appBIS;
            case OperatingSystems.WINDOWS_SERVER:
                return this.valuesService.appWS;
            case OperatingSystems.WINDOWS:
                if ((device.processed.clMajorVersionInstalled).toString() > this.devicesValuesService.clVersion) {
                    return this.valuesService.appCL; // OLD VALUE.
                } else {
                    return this.valuesService.appOptimizeDvm; // NEW VALUE FOR ALPHA
                }
            default:
                return undefined;
        }
    }

}
